import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Layout, Menu, Button, theme, Breadcrumb } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { UserCircle, Hammer, CalendarClock, FileSearch, Briefcase, Building, ClipboardCheck, HardHat, UserPlus, Wallet } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployerBroadcastJob } from '../../features/Booking';

const { Header, Sider, Content } = Layout;

const Bookings = () => {
   const [collapsed, setCollapsed] = useState(false);
   const location = useLocation();
   const dispatch = useDispatch();
   const user = useSelector((store) => store.auth.user);
   const [wid, setWid] = useState(false);
   const { employerBroadcastJob } = useSelector((store) => store.booking);

   const {
      token: { colorBgContainer, borderRadiusLG },
   } = theme.useToken();

   useEffect(() => {
      dispatch(getEmployerBroadcastJob());
   }, [dispatch]);

   useEffect(() => {
      const checkAndCollapseSidebar = () => {
         setCollapsed(window.innerWidth < 768);
         setWid(window.innerWidth > 768);
      };

      checkAndCollapseSidebar();
      window.addEventListener('resize', checkAndCollapseSidebar);
      return () => window.removeEventListener('resize', checkAndCollapseSidebar);
   }, []);

   useEffect(() => {
      if (window.innerWidth < 768) {
         setCollapsed(true);
      }
   }, [location.pathname]);

   const isActive = (path) => location.pathname.includes(path);

   // Generate items for Worker section
   const getWorkerItems = () => {
      if (!user?.workerId) return [];

      return [
         {
            key: 'profile',
            icon: <UserCircle size={16} />,
            label: <Link to="/Bookings/profile">My Profile</Link>,
         },
         {
            key: 'worker-section',
            icon: <HardHat size={16} />,
            label: 'As a Worker',
            children: [
               {
                  key: 'availableBookings',
                  icon: <FileSearch size={16} />,
                  label: <Link to="/Bookings/availableBookings">Find Available Jobs</Link>,
               },
               {
                  key: 'getBroadcastJobs',
                  icon: <ClipboardCheck size={16} />,
                  label: <Link to="/Bookings/getBroadcastJobs">My Applied Jobs</Link>,
               },
               {
                  key: 'broadcastJob',
                  icon: <CalendarClock size={16} />,
                  label: <Link to="/Bookings/broadcastJob">My Tasks</Link>,
               },
            ],
         },
      ];
   };

   // Generate items for Employer section
   const getEmployerItems = () => {
      const items = [
         {
            key: 'employer-section',
            icon: <Building size={16} />,
            label: 'As an Employer',
            children: [
               {
                  key: 'createBroadcastJob',
                  icon: <Hammer size={16} />,
                  label: <Link to="/Bookings/createBroadcastJob">Post Job Opening</Link>,
               },
               {
                  key: 'bookingsAsEmployer',
                  icon: <Briefcase size={16} />,
                  label: <Link to="/Bookings/bookingsAsEmployer">My Posted Jobs</Link>,
               },
            ],
         },
      ];

      // Add Manage Applicants conditionally
      if (employerBroadcastJob?.broadcastJobs?.length > 0) {
         items[0].children.push({
            key: 'manageApplicants',
            icon: <UserPlus size={16} />,
            label: <Link to="/Bookings/manageApplicants">Manage Applicants</Link>,
         });
      }

      return items;
   };

   // Generate payment methods item
   const getPaymentItem = () => [
      {
         key: 'allPaymentMethods',
         icon: <Wallet size={16} />,
         label: <Link to="/Bookings/allPaymentMethods">Payment Methods</Link>,
      },
   ];

   // Combine all menu items
   const menuItems = [
      ...getWorkerItems(),
      ...getEmployerItems(),
      ...getPaymentItem(),
   ];

   return (
      <Layout style={{ minHeight: '100vh' }}>
         <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            theme="light"
            breakpoint="md"
            onBreakpoint={setCollapsed}
            width={250}
            collapsedWidth="70"
            style={{
               padding: '12px 0',
               borderRight: '1px solid #f0f0f0',
               backgroundColor: '#f9f9f9'
            }}
         >
            <div style={{ height: 32, margin: 16, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
               <div className="text-black bold">{collapsed ? 'DB' : 'Dashboard'}</div>
            </div>
            <Menu
               theme="light"
               mode="inline"
               defaultSelectedKeys={[location.pathname.split('/').pop()]}
               defaultOpenKeys={[
                  isActive('availableBookings') || isActive('getBroadcastJobs') || isActive('broadcastJob') ? 'worker-section' : null,
                  isActive('createBroadcastJob') || isActive('bookingsAsEmployer') || isActive('manageApplicants') ? 'employer-section' : null,
               ].filter(Boolean)}
               items={menuItems}
               style={{ padding: '0 8px' }}
            />
         </Sider>
         <Layout>
            {wid && (
               <Header style={{ padding: 0, background: colorBgContainer }}>
                  <Button
                     type="text"
                     icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                     onClick={() => setCollapsed(!collapsed)}
                     style={{
                        fontSize: '16px',
                        width: 64,
                        height: 64,
                     }}
                  />
               </Header>
            )}

            <Content
               style={{
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                  minHeight: 280,
               }}
            >
               <Breadcrumb
                  style={{
                     margin: '16px 16px',
                     padding: '8px 16px',
                     background: '#fff',
                     borderRadius: '4px',
                     boxShadow: '0 1px 2px rgba(0,0,0,0.03)'
                  }}
                  items={[
                     {
                        title: <Link to="/">Home</Link>
                     },
                     {
                        title: <Link to="/Bookings">Bookings</Link>
                     },
                     ...location.pathname.split('/').filter((path, index) => index > 1 && path).map((path, index, array) => {
                        const url = `/Bookings/${array.slice(0, index + 1).join('/')}`;
                        const formattedPath = path
                           .replace(/([A-Z])/g, ' $1')
                           .replace(/^./, str => str.toUpperCase());

                        return {
                           title: index === array.length - 1 ? (
                              formattedPath
                           ) : (
                              <Link to={url}>{formattedPath}</Link>
                           )
                        };
                     })
                  ]}
               />
               <Outlet />
            </Content>
         </Layout>
      </Layout>
   );
}

export default Bookings;