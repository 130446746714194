import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import { toast } from "react-hot-toast";

const initialState = {
   isError: false,
   isSuccess: false,
   isLoading: false,
   employerBookings: '',
   workerBookings: '',
   pendingBookingsByWorker: '',
   broadcastJobs: '',
   employerBroadcastJob: '',
   applicantsByJob: '',
   message: "",
};

//////////////////////Booking Worker///////////////////////////
export const BookWorker = createAsyncThunk(
   "/auth/bookWorker",
   async (data, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/booking/bookWorker`;
         const response = await axios.post(API_URL, data, { withCredentials: true });
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//////////////////////Get Bookings by Worker ID///////////////////////////
export const getBookingsByWorkerID = createAsyncThunk(
   "/auth/getBookingsByWorkerID",
   async (workerId, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/booking/getBookingsByWorkerId/${workerId}`;
         const response = await axios.get(API_URL, { withCredentials: true });
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);


//////////////////////Get Employer Bookings///////////////////////////
export const getEmployerBookings = createAsyncThunk(
   "/auth/getEmployerBookings",
   async (_, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/booking/getEmployerBookings`;
         const response = await axios.get(API_URL, { withCredentials: true });

         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//Accept Booking By Worker
export const acceptBookingByWorker = createAsyncThunk(
   "/auth/acceptBookingByWorker",
   async (data, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/booking/acceptBookingByWorker`;
         const response = await axios.put(API_URL, data, { withCredentials: true });

         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//Reject Booking By Employer
export const rejectBookingByWorker = createAsyncThunk(
   "/auth/rejectBookingByWorker",
   async (data, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/booking/rejectBookingByWorker`;
         const response = await axios.put(API_URL, data, { withCredentials: true });
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//updateBookingStatusByWorker
export const updateBookingStatusByWorker = createAsyncThunk(
   "/auth/acceptBookingByEmployer",
   async (data, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/booking/updateBookingStatusByWorker`;
         const response = await axios.patch(API_URL, data, { withCredentials: true });
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//Create Broadcast Job

export const createBroadcastJob = createAsyncThunk(
   "/auth/createBroadcastJob",
   async (data, thunkAPI) => {
      try {
         const API_URL = `${process.env.REACT_APP_SERVER_URL}/broadcast/createBroadcastJob`;
         const response = await axios.post(API_URL, data, { withCredentials: true });
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//get All Broadcast job
export const getBroadcastJob = createAsyncThunk(
   "/auth/getBroadcastJob",
   async (workerId, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/broadcast/getAllBroadcastJobs/${workerId}`;
         const response = await axios.get(API_URL, { withCredentials: true });

         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//Apply for Broadcast job
export const applyForBroadcastJob = createAsyncThunk(
   "/auth/applyForBroadcastJob",
   async (data, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/broadcast/applyForBroadcastJob`;
         const response = await axios.post(API_URL, data, { withCredentials: true });
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//get Employer Broadcast job
export const getEmployerBroadcastJob = createAsyncThunk(
   "/auth/getEmployerBroadcastJobs",
   async (_, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/broadcast/getEmployerBroadcastJobs`;
         const response = await axios.get(API_URL, { withCredentials: true });

         return response.data;

      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//handle broadcast Application by employer
export const handleBroadcastApplication = createAsyncThunk(
   "/auth/handleBroadcastApplicationByEmployer",
   async (data, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/broadcast/handleBroadcastApplicationByEmployer`;
         const response = await axios.post(API_URL, data, { withCredentials: true });
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

// get broadcast job by worker Id
export const getBroadcastJobsByWorkerId = createAsyncThunk(
   "/auth/getBroadcastJobsByWorkerId",
   async (workerId, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/broadcast/getBroadcastJobsByWorkerId/${workerId}`;
         const response = await axios.get(API_URL, { withCredentials: true });
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//update broadcast job by worker
export const updateBroadcastJobStatusByWorker = createAsyncThunk(
   "/auth/updateBroadcastJobByWorker",

   async (data, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/broadcast/updateBroadcastJobByWorker`;
         const response = await axios.patch(API_URL, data, { withCredentials: true });
         return response.data;
      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);
      }
   }
);

//get All Applications For Broadcastjob by broadcastjobId
export const getAllApplicantsForBroadcastJob = createAsyncThunk(
   "/auth/getAllApplicantsForBroadcastJob",
   async (broadcastJobId, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/broadcast/getAllApplicantsForBroadcastJob/${broadcastJobId}`;
         const response = await axios.get(API_URL, { withCredentials: true });
         return response.data;

      } catch (error) {
         const message =
            (error.response &&
               error.response.data &&
               error.response.data.message) ||
            error.response.data.status ||
            error.message ||
            error.toString();
         return thunkAPI.rejectWithValue(message);

      }
   }
);







export const bookingSlice = createSlice({
   name: "booking",
   initialState,
   reducers: {
      clearState: (state) => {
         state.isError = false;
         state.isSuccess = false;
         state.isLoading = false;
         state.message = "";
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(BookWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(BookWorker.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(BookWorker.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(getBookingsByWorkerID.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getBookingsByWorkerID.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
            state.workerBookings = payload;
         })
         .addCase(getBookingsByWorkerID.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(getEmployerBookings.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getEmployerBookings.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
            state.employerBookings = payload;
         })
         .addCase(getEmployerBookings.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(acceptBookingByWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(acceptBookingByWorker.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(acceptBookingByWorker.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(rejectBookingByWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(rejectBookingByWorker.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(rejectBookingByWorker.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(updateBookingStatusByWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateBookingStatusByWorker.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(updateBookingStatusByWorker.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(createBroadcastJob.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createBroadcastJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(createBroadcastJob.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         }
         )
         .addCase(getBroadcastJob.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getBroadcastJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
            state.broadcastJobs = payload;
         })
         .addCase(getBroadcastJob.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(applyForBroadcastJob.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(applyForBroadcastJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(applyForBroadcastJob.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(getEmployerBroadcastJob.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(getEmployerBroadcastJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
            state.employerBroadcastJob = payload;
         })
         .addCase(getEmployerBroadcastJob.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(handleBroadcastApplication.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(handleBroadcastApplication.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(handleBroadcastApplication.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(getBroadcastJobsByWorkerId.pending, (state) => {
            state.isLoading = true;
         }
         )
         .addCase(getBroadcastJobsByWorkerId.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
            state.pendingBookingsByWorker = payload;
         })
         .addCase(getBroadcastJobsByWorkerId.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(updateBroadcastJobStatusByWorker.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(updateBroadcastJobStatusByWorker.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(updateBroadcastJobStatusByWorker.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
         .addCase(getAllApplicantsForBroadcastJob.pending, (state) => {
            state.isLoading = true;
         }
         )
         .addCase(getAllApplicantsForBroadcastJob.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
            state.applicantsByJob = payload;
         })
         .addCase(getAllApplicantsForBroadcastJob.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
         })
   }
});

export const { clearState } = bookingSlice.actions;

export default bookingSlice.reducer;