import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { getUser, logout } from "../../features/AuthSlice";
import { getAllChatsOfTheUser } from '../../features/AuthSlice';
// import Select from "react-select";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChevronDown, MapPin } from "lucide-react";
import { updateAddress } from "../../features/AuthSlice";
import { setLocation, getReverseGeocode } from "../../features/locationSlice";
import { Heart, User, CreditCard, LogOut } from "lucide-react";
import "./Header.css";

// Components
import HeaderAddressModal from "../HeaderAddressModal/HeaderAddressModal";

// Assets
import fori_logo from "../../Assets/Images/fori Logo.jpg";
import { Avatar, Select, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
// Actions
import { getAllSkills } from "../../features/AdminSlice";
import { getBookingsByWorkerID } from "../../features/Booking";
import NotificationPanel from "../TabbedNotificationPanel/TabbedNotificationPanel";

//import become a partner modal
import BecomeAPartner from "../BecomeAPartner/BecomeAPartner";

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const notificationRef = useRef(null);
    const dropdownRef = useRef(null);
    const locationFetchedRef = useRef(false);

    // Selectors
    const { user } = useSelector((store) => store.auth);
    const { allSkills } = useSelector((store) => store.admin);
    const { workerBookings } = useSelector((store) => store.booking);
    const storeLocation = useSelector((store) => store.location.location);
    const textualAddress = useSelector((store) => store.location.textual_address);
    const chat = useSelector((store) => store.auth.chat);

    // State
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
    const [address, setAddress] = useState("");
    const [addressUnderIcon, setAddressUnderIcon] = useState('');

    //modal Open Become a partner

    const [isBecomePartnerModalOpen, setIsBecomePartnerModalOpen] = useState(false);


    // Initialize Geocode and fetch user data
    useEffect(() => {
        dispatch(getUser());
        dispatch(getAllSkills());
    }, [dispatch]);

    // Fetch worker bookings
    const fetchBookings = useCallback(() => {
        if (user?.workerId) {
            dispatch(getBookingsByWorkerID(user.workerId));
        }
    }, [dispatch, user]);

    useEffect(() => {
        fetchBookings();
    }, [fetchBookings]);

    // Fetch chats
    useEffect(() => {
        if (user) {
            dispatch(getAllChatsOfTheUser(user.id));
        }
    }, [dispatch, user]);

    // Process notifications
    useEffect(() => {
        if (user?.workerId && workerBookings?.bookings) {
            const now = new Date();
            const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

            const pendingBookings = workerBookings.bookings.filter(
                booking => booking.jobStatus === "pending" &&
                    new Date(booking.bookingCreatedAt) > sevenDaysAgo
            );

            const newNotifications = pendingBookings.map(booking => ({
                id: booking._id,
                message: `New booking request for ${booking.skill}`,
                createdAt: new Date(booking.bookingCreatedAt)
            }));

            setNotifications(newNotifications.sort((a, b) => b.createdAt - a.createdAt));
        } else {
            setNotifications([]);
        }
    }, [user, workerBookings]);


    useEffect(() => {
        const fetchLocation = async () => {
            // Only attempt to fetch location once
            if (
                !locationFetchedRef.current &&
                Object.keys(storeLocation).length === 0 &&
                Object.keys(textualAddress).length === 0
            ) {
                locationFetchedRef.current = true;

                if (!navigator.geolocation) {
                    toast.error("Location services are not supported by your browser. Please try a different browser.");
                    return;
                }

                // Convert getCurrentPosition to a Promise
                const getPosition = () => {
                    return new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(
                            position => resolve(position),
                            error => reject(error),
                            {
                                enableHighAccuracy: true,
                                timeout: 15000,
                                maximumAge: 0
                            }
                        );
                    });
                };

                try {
                    const position = await getPosition();
                    const location = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                    };

                    try {
                        const reverseGeocodeResult = await dispatch(getReverseGeocode({
                            lat: location.latitude,
                            lon: location.longitude
                        })).unwrap();

                        if (reverseGeocodeResult.success) {
                            dispatch(setLocation({
                                location,
                                address: reverseGeocodeResult.address
                            }));
                            if (user) {
                                dispatch(updateAddress({
                                    lat: location.latitude,
                                    lng: location.longitude,
                                    city: reverseGeocodeResult.address.components._normalized_city,
                                    textAddress: reverseGeocodeResult.address.formatted
                                }));
                            }

                        } else {
                            toast.error(reverseGeocodeResult.message || "Unable to determine your location details. Please try entering your address manually.");
                        }
                    } catch (geocodeError) {
                        console.error("Geocode error:", geocodeError);
                        toast.error("We couldn't process your location information. Please try again or enter your address manually.");
                    }
                } catch (error) {
                    console.error("Geolocation error:", error);
                    const errorMessage =
                        error.code === 1
                            ? "Location access denied. Please enable location services in your browser settings and refresh the page."
                            : error.code === 2
                                ? "Your location couldn't be determined. Please check your internet connection or try again later."
                                : "Unable to access your location. Please try refreshing the page or enter your address manually.";

                    toast.error(errorMessage);
                }
            }
        };

        fetchLocation();
    }, [dispatch, user]);

    useEffect(() => {
        if (textualAddress) {
            const city = textualAddress?.components?._normalized_city ||
                textualAddress?.components?.district ||
                textualAddress?.components?.town ||
                'Location';
            setAddressUnderIcon(`${city}`);
        }
    }, [textualAddress]);

    // Click outside handlers
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!notificationRef.current?.contains(event.target)) {
                setShowNotifications(false);
            }
            if (!dropdownRef.current?.contains(event.target)) {
                setIsUserDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Handlers
    const handleRegisterClick = () => {
        if (!user) {
            toast("Please sign in to register a worker");
        }
    };

    const handleSkillChange = useCallback((selectedOption) => {
        if (selectedOption) {
            navigate(`/skill?q=${encodeURIComponent(selectedOption.value)}&exact=true`);
        }
    }, [navigate]);

    const handleNotificationClick = () => {
        setShowNotifications(false);
        navigate('/bookings/availableBookings');
    };

    const handleLogout = () => {
        localStorage.removeItem('becomeAWorkerData');
        localStorage.removeItem('addAWorkerData');
        dispatch(logout()).then(() => navigate("/signin"));
        setIsUserDropdownOpen(false);
        localStorage.removeItem('counterAnimationPlayed');
    };

    const getTimeAgo = (date) => {
        const now = new Date();
        const diffInMinutes = Math.floor((now - date) / (1000 * 60));

        if (diffInMinutes < 60) {
            return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
        }
        if (diffInMinutes < 24 * 60) {
            const diffInHours = Math.floor(diffInMinutes / 60);
            return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
        }
        const diffInDays = Math.floor(diffInMinutes / (24 * 60));
        return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
    };

    const pathName = window.location.pathname;

    const selectOptions = useMemo(() => {
        return allSkills.map(option => ({
            value: option.name,
            label: option.name
        }));
    }, [allSkills]);

    console.log("Hello World");


    return (
        <div className="Bannerheader">
            <div className="header_left">
                <Link to="/">
                    <img src={fori_logo} alt="Fori Logo" />
                </Link>
                <div className="header_right flex_row">
                    <NavLink
                        className={pathName === '/' ? "activeLink" : "non_active"}
                        to="/"
                    >
                        HOME
                    </NavLink>

                    <NavLink
                        className={pathName === '/about' ? "activeLink" : "non_active"}
                        to="/about"
                    >
                        ABOUT
                    </NavLink>
                    {user?.workerId && (
                        <NavLink to="/Bookings/profile">
                            MY PROFILE
                        </NavLink>
                    )}
                    {user && (
                        <div className="dropdown">
                            <NavLink className="dropbtn">
                                DASHBOARD
                                <ChevronDown className="dropdown-arrow" size={16} />
                            </NavLink>

                            <div className="dropdown-content">
                                <NavLink to="/Bookings">
                                    BOOKINGS
                                </NavLink>
                                <NavLink to="/Bookings/createBroadcastJob">
                                    BROADCAST JOB
                                </NavLink>
                            </div>
                        </div>
                    )}

                    <NavLink
                        className={pathName.startsWith('/skills') ? "activeLink" : "non_active"}
                        to="/skills"
                    >
                        SKILLS
                    </NavLink>
                    {/* <NavLink
                        className={pathName.startsWith('/analytics') ? "activeLink" : "non_active"}
                        to="/analytics"
                    >
                        ANALYTICS
                    </NavLink> */}

                    <div className="dropdown">
                        <NavLink className="dropbtn">
                            WORKER REGISTRATION
                            <ChevronDown className="dropdown-arrow" size={16} />
                        </NavLink>
                        <div className="dropdown-content">
                            {!user?.workerId && (
                                <NavLink
                                    to={user ? "/register-as-worker" : "/signin"}
                                    onClick={handleRegisterClick}
                                >
                                    BECOME A WORKER
                                </NavLink>
                            )}



                            <NavLink
                                to={user ? "/addAWorker" : "/signin"}
                                onClick={handleRegisterClick}
                            >
                                ADD A WORKER
                            </NavLink>
                            <NavLink
                                to={user?.partnerId ? "/register-multiple-workers" : "#"}
                                onClick={(e) => {
                                    if (!user) {
                                        e.preventDefault();
                                        toast("Please sign in to become a partner");
                                        navigate("/signin");
                                    } else if (!user?.partnerId) {
                                        e.preventDefault();
                                        setIsBecomePartnerModalOpen(true);
                                    }

                                }}
                                className="nav-link partner-link"
                            >
                                {user?.partnerId ? "REGISTER MULTIPLE WORKERS" : "BECOME A PARTNER"}
                            </NavLink>

                            <BecomeAPartner
                                isOpen={isBecomePartnerModalOpen}
                                close={() => setIsBecomePartnerModalOpen(false)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex_row_center">
                <div className="search_Worker_Field">
                    <Space
                        direction="vertical"
                        className="w-full"
                    >
                        <Select
                            showSearch
                            size="large"
                            className="custom-select1 w-[150px] border-none shadow-none focus:ring-0"
                            onSelect={(value) => {
                                const option = selectOptions.find(opt => opt.value === value);
                                handleSkillChange(option);

                            }}
                            options={selectOptions}
                            placeholder="Search a Worker Here"
                            virtual={false}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />
                    </Space>
                </div>

                {user && (
                    <NotificationPanel
                        notifications={notifications}
                        chats={chat || []}
                        handleNotificationClick={handleNotificationClick}
                        getTimeAgo={getTimeAgo}
                        user={user}
                    />
                )}

                <div className="location location_heading">
                    <button
                        className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                        onClick={() => setIsAddressModalOpen(true)}
                    >
                        <MapPin size={20} color={addressUnderIcon ? "#12333a" : "#94a3b8"} />
                    </button>

                    <h6 className="addressUnderIcon text-center hidden md:block">
                        {addressUnderIcon || 'Loading...'}
                    </h6>

                    {isAddressModalOpen && (
                        <HeaderAddressModal
                            open={isAddressModalOpen}
                            onClose={() => setIsAddressModalOpen(false)}
                            address={address}
                            setAddress={setAddress}
                        />
                    )}
                </div>

                {/* avatar dropdown*/}
                {user ? (
                    <div className="avatar-container" ref={dropdownRef}>
                        <Avatar
                            onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
                            className="avatar-button"
                            src={user?.avatar}
                            style={{
                                cursor: 'pointer',
                                backgroundColor: '#12333a'
                            }}
                        >
                            {user?.name ? user.name.charAt(0).toUpperCase() : <UserOutlined />}
                        </Avatar>

                        {isUserDropdownOpen && (
                            <div className="dropdownn">
                                <div className="user-info">
                                    <p className="user-name">
                                        <strong>{user.name}</strong>
                                    </p>
                                    {user.email && (
                                        <p className="user-email">
                                            {user.email}
                                        </p>
                                    )}
                                    {user.phone && (
                                        <p className="user-phone">
                                            {user.phone}
                                        </p>
                                    )}
                                    {user.provider && (
                                        <p className="user-phone">
                                            {user.provider}
                                        </p>
                                    )}
                                    <p className="user-role hover:underline">
                                        {user.workerId ? "User, Worker" : <><Link to="/register-as-worker">BECOME A WORKER</Link></>}
                                    </p>
                                </div>

                                <nav className="dropdown-menu">
                                    <Link
                                        to="/favorites"
                                        className="dropdown-item"
                                        onClick={() => setIsUserDropdownOpen(false)}
                                    >
                                        <div className="flex items-center">
                                            <Heart size={16} className="mr-2" />
                                            Favorites
                                        </div>
                                    </Link>

                                    {user?.workerId && (
                                        <Link
                                            to="/Bookings/profile"
                                            className="dropdown-item"
                                            onClick={() => setIsUserDropdownOpen(false)}
                                        >
                                            <div className="flex items-center">
                                                <User size={16} className="mr-2" />
                                                My Worker Profile
                                            </div>
                                        </Link>
                                    )}

                                    <Link
                                        to="/Bookings/allPaymentMethods"
                                        className="dropdown-item"
                                        onClick={() => setIsUserDropdownOpen(false)}
                                    >
                                        <div className="flex items-center">
                                            <CreditCard size={16} className="mr-2" />
                                            Payment Methods
                                        </div>
                                    </Link>
                                </nav>

                                <button className="logOut_btn" onClick={handleLogout}>
                                    <div className="flex items-center">
                                        <LogOut size={16} className="mr-2" />
                                        Logout
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <Link className="btn" to="/signin">
                        SIGN IN
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Header;