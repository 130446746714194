import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchFavorites, updateFavoriteWorker } from "../../features/favoritesSlice";
import male from "../../Assets/Images/male.png";
import Spinner from "../../Components/Spinner/Spinner";
import { Heart } from "lucide-react";
import { Tooltip } from "react-tooltip";
import toast from "react-hot-toast";
import { Pagination } from "antd";
import "../WorkersPerSkill/WorkersPerSkill.css";

const FavoriteWorkers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);
  const itemsPerPage = 20;

  // Get page from URL or default to 1
  const currentPage = parseInt(searchParams.get("page") || "1", 10);

  const { user } = useSelector((store) => store.auth);
  const { favoriteWorkers, totalFavoriteWorkers } = useSelector((store) => store.favorites);



  useEffect(() => {
    if (user) {
      dispatch(fetchFavorites(currentPage))
        .catch((error) => {
          console.error("Error fetching favorites:", error);
          toast.error("Failed to load favorite workers");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [user, dispatch, currentPage]);

  const handleWorkerClick = (slug) => {
    const params = new URLSearchParams(searchParams);
    params.set('id', slug);
    navigate(`/worker?${params.toString()}`);
  };

  const getWorkerImage = (profileImg) => {
    return profileImg && profileImg.trim() ? profileImg : male;
  };

  const capitalizeName = (name) => {
    return name?.split(" ")?.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(" ");
  };

  const setPageChange = (page) => {
    setIsLoading(true);
    const params = new URLSearchParams(searchParams);
    params.set("page", page.toString());
    setSearchParams(params);
  };

  const removeFromFavorites = (workerId) => {
    dispatch(updateFavoriteWorker(workerId))
      .then((result) => {
        toast.success(`${result.payload.message}`);
        return dispatch(fetchFavorites(currentPage));
      })
      .catch((error) => {
        console.error("Error updating favorites:", error);
        toast.error("Failed to remove from favorites");
      });
  };

  const totalPages = Math.ceil(totalFavoriteWorkers / itemsPerPage);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="site_padding favorite-workers-page">
          {!user ? (
            <h5>Please Sign in to View Your Favorite Workers</h5>
          ) : (
            <>
              <h5>Total active favorite workers: {totalFavoriteWorkers}</h5>
              <div className="worker-list">
                {favoriteWorkers?.map((fd) => {
                  return (
                    <div key={fd._id}>
                      <div className="worker-item box_shadow">
                        <div className="worker-image cursor-pointer">
                          <img
                            src={getWorkerImage(fd?.profileImg)}
                            alt={capitalizeName(fd.name)}
                            onClick={() => handleWorkerClick(fd.slug)}
                          />
                        </div>
                        <div className="worker-details">
                          <Heart
                            size={16}
                            data-tooltip-id={`tooltip-${fd._id}`}
                            data-tooltip-content="Remove from Favorites"
                            className="favIcon favoriteIcon"
                            onClick={() => removeFromFavorites(fd._id)}
                          />
                          <Tooltip id={`tooltip-${fd._id}`} place="top" effect="solid" />
                          <h5 onClick={() => handleWorkerClick(fd.slug)} className="cursor-pointer hover:underline">
                            {capitalizeName(fd.name)}
                          </h5>

                          <p className="worker-skill">
                            {fd.skill && Array.isArray(fd.skill) && fd.skill.length > 0
                              ? fd.skill.join(", ")
                              : "Skills not available"}
                          </p>

                          <p className="worker-city">
                            {fd.city ? capitalizeName(fd.city) : "City not available"}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    current={currentPage}
                    total={totalPages * itemsPerPage}
                    pageSize={itemsPerPage}
                    onChange={setPageChange}
                    showSizeChanger={false}
                  />
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default FavoriteWorkers;
