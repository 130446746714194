import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { ConfigProvider } from 'antd';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ConfigProvider theme={{
      token: {
        // colorPrimary: '#12333a',
        // colorPrimary: '#12333a',
        colorPrimary: '#00796B',
        colorPrimaryHover: '#22535b',
        colorPrimaryActive: '#22535b',
        colorText: '#0e0e0e',
        colorTextSecondary: '#a0a0a0',
        colorBgContainer: '#ffffff',
        colorBgElevated: '#f0f0f0',
        colorError: '#f44336',
        colorSuccess: '#4caf50',
        colorInfo: '#3f51b5',
        boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      },
      components: {
        Carousel: {
          dotOffset: 20,
          dotHeight: 4,
        },
        Input: {
          activeBorderColor: '#12333a',
          hoverBorderColor: '#12333a',
          activeShadowColor: "none",
          colorBorder: '#a0a0a0',
          activeShadow: "none",
        },
        Button: {
          defaultColor: '#12333a',
          colorPrimary: '#12333a',
          defaultBorderColor: '#12333a',
          defaultActiveColor: '#22535b',
          defaultActiveBorderColor: "none",
          colorPrimaryHover: '#22535b',
          borderRadius: 16,
        },
        Card: {
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        },
        Select: {
          colorBorder: '#a0a0a0',
          colorPrimaryHover: '#22535b',
          colorTextPlaceholder: '#6c757d',
        },
        Table: {
          headerBg: '#f0f0f0',
          borderColor: '#a0a0a0',
        },
        Pagination: {
          colorPrimary: '#22535b',
        },
        Typography: {
          fontSizeHeading1: '2.5rem',
          fontSizeHeading2: '2rem',
          fontSizeHeading3: '1.5rem',
          fontSizeHeading4: '1.5rem',
          fontSizeHeading5: '1rem',
        },
        Rate: {
          starColor: '#12333a'
        },
        Switch: {
          colorPrimary: '#12333a',
        },
      },
    }}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);

reportWebVitals();