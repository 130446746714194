import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import { toast } from "react-hot-toast";

const initialState = {
   isError: false,
   isSuccess: false,
   isLoading: false,
   paymentMethods: [],
   message: "",
};

export const createPaymentMethod = createAsyncThunk(
   "paymentMethod/createPaymentMethod",
   async (data, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/payment/attachPaymentMethod`;
         const response = await axios.post(API_URL, data, { withCredentials: true });
         return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue(error.response.data);
      }
   }
);

// Retrieve all Payment Methods
export const retrievePaymentMethods = createAsyncThunk(
   "paymentMethod/retrievePaymentMethods",
   async (customerId, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/payment/retrieveAllPaymentMethods/${customerId}`;
         const response = await axios.get(API_URL, { withCredentials: true });

         return response.data;
      } catch (error) {
         const errorMessage = error.response?.data?.message || error.message || "An error occurred";
         return thunkAPI.rejectWithValue(errorMessage);
      }
   }
);

// Detach Payment Method
export const detachPaymentMethod = createAsyncThunk(
   "paymentMethod/detachPaymentMethod",
   async (paymentMethodId, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/payment/detachPaymentMethod`;
         const response = await axios.post(API_URL, paymentMethodId, { withCredentials: true });
         return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue(error.response.data);
      }
   }
);


// Set Default Payment Method
export const setDefaultPaymentMethod = createAsyncThunk(
   "paymentMethod/setDefaultPaymentMethod",
   async (paymentMethodId, thunkAPI) => {
      try {

         const API_URL = `${process.env.REACT_APP_SERVER_URL}/payment/setDefaultPaymentMethod`;
         const response = await axios.post(API_URL, paymentMethodId, { withCredentials: true });
         return response.data;
      } catch (error) {
         return thunkAPI.rejectWithValue(error.response.data);
      }
   }
);



export const PaymentMethodSlice = createSlice({
   name: "paymentMethod",
   initialState,
   reducers: {
      clearState: (state) => {
         state.isError = false;
         state.isSuccess = false;
         state.isLoading = false;
         state.message = "";
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(createPaymentMethod.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(createPaymentMethod.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(createPaymentMethod.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload.message;
         })
         .addCase(retrievePaymentMethods.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(retrievePaymentMethods.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.paymentMethods = payload.allPaymentMethods;
            state.message = payload.message;
         })
         .addCase(retrievePaymentMethods.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload.message;
         })
         .addCase(detachPaymentMethod.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(detachPaymentMethod.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(detachPaymentMethod.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload.message;
         })
         .addCase(setDefaultPaymentMethod.pending, (state) => {
            state.isLoading = true;
         })
         .addCase(setDefaultPaymentMethod.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.message = payload.message;
         })
         .addCase(setDefaultPaymentMethod.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload.message;
         });
   },
});

export default PaymentMethodSlice.reducer;