// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancel-page {
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 48px 16px;
}

.cancel-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.cancel-message {
    text-align: center;
    margin-bottom: 24px;
}

.cancel-icon {
    font-size: 64px;
    color: #ff4d4f;
    margin-bottom: 24px;
}

.actions-card,
.help-card {
    background: white;
    border-radius: 8px;
}

/* Responsive adjustments */
@media (max-width: 576px) {
    .cancel-page {
        padding: 24px 16px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/DonateSomaliaCancel/DonateSomaliaCancel.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,mBAAmB;AACvB;;AAEA;;IAEI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA,2BAA2B;AAC3B;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".cancel-page {\n    min-height: 100vh;\n    background-color: #f5f5f5;\n    padding: 48px 16px;\n}\n\n.cancel-container {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n\n.cancel-message {\n    text-align: center;\n    margin-bottom: 24px;\n}\n\n.cancel-icon {\n    font-size: 64px;\n    color: #ff4d4f;\n    margin-bottom: 24px;\n}\n\n.actions-card,\n.help-card {\n    background: white;\n    border-radius: 8px;\n}\n\n/* Responsive adjustments */\n@media (max-width: 576px) {\n    .cancel-page {\n        padding: 24px 16px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
