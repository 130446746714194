import React, { useState, useEffect, useRef } from 'react';
import { Bell, MessageCircle, Clock } from 'lucide-react';
import "./TabbedNotificationPanel.css"
import ChatComponent from '../InAppChat/InAppChat';

const NotificationPanel = ({
    notifications = [],
    chats = [],
    handleNotificationClick,
    getTimeAgo,
    user
}) => {
    const [showNotifications, setShowNotifications] = useState(false);
    const [activeTab, setActiveTab] = useState('notifications');
    const [isMobile, setIsMobile] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const panelRef = useRef(null);
    const buttonRef = useRef(null);
    chats = Array.isArray(chats) ? chats : [];


    useEffect(() => {
        const checkIfMobile = () => setIsMobile(window.innerWidth <= 550);
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                showNotifications &&
                panelRef.current &&
                buttonRef.current &&
                !panelRef.current.contains(event.target) &&
                !buttonRef.current.contains(event.target)
            ) {
                setShowNotifications(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [showNotifications]);

    const handleChatClick = (chat) => {
        setSelectedChat(chat);
        setIsChatOpen(true);
        setShowNotifications(false);
    };

    const formatMessageTime = (timestamp) => {
        const date = new Date(timestamp);
        const now = new Date();
        const diff = now - date;
        const minutes = Math.floor(diff / 60000);
        const hours = Math.floor(diff / 3600000);
        const days = Math.floor(diff / 86400000);

        if (minutes < 60) return `${minutes}m ago`;
        if (hours < 24) return `${hours}h ago`;
        if (days < 7) return `${days}d ago`;
        return date.toLocaleDateString();
    };

    const renderNotificationItem = (notif) => (
        <div
            key={notif.id}
            className="tab-notif-item"
            onClick={() => {
                handleNotificationClick();
                setShowNotifications(false);
            }}
        >
            <div className="tab-notif-icon">
                <Bell size={20} color="#12333a" />
            </div>
            <div className="tab-notif-content-wrapper">
                <p className="tab-notif-message-text">{notif.message}</p>
                <div className="tab-notif-timestamp">
                    <Clock />
                    {getTimeAgo(notif.createdAt)}
                </div>
            </div>
        </div>
    );

    const renderChatItem = (chat) => (
        <div
            key={chat.chatId}
            className="tab-notif-chat-item"
            onClick={() => handleChatClick(chat)}
        >
            <div className="tab-notif-chat-avatar">
                {chat?.otherParticipant?.name?.charAt(0) || '?'}
            </div>
            <div className="tab-notif-content-wrapper">
                <div className="tab-notif-chat-header">
                    <span className="tab-notif-chat-name">
                        {chat?.otherParticipant?.name || 'Unknown User'}
                    </span>
                    <span className="tab-notif-chat-time">
                        {chat?.lastMessage?.createdAt ? formatMessageTime(chat.lastMessage.createdAt) : ''}
                    </span>
                </div>
                <p className="tab-notif-chat-preview">
                    {chat?.lastMessage?.text || 'No message'}
                </p>
            </div>
        </div>
    );

    const renderEmptyState = (type) => (
        <div className="tab-notif-empty-state">
            {type === 'notifications' ? (
                <>
                    <Bell />
                    <p className="tab-notif-empty-state-title">No new notifications</p>
                    <p className="tab-notif-empty-state-description">We'll notify you when something arrives.</p>
                </>
            ) : (
                <>
                    <MessageCircle />
                    <p className="tab-notif-empty-state-title">No messages yet</p>
                    <p className="tab-notif-empty-state-description">Start a conversation with someone.</p>
                </>
            )}
        </div>
    );

    return (
        <>
            <div className="tab-notif-container">
                <button
                    ref={buttonRef}
                    className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                    onClick={() => setShowNotifications(!showNotifications)}
                >
                    <Bell size={20} />
                    {/* {((notifications?.length || 0) + (chats?.length || 0)) > 0 && (
                        <span className="tab-notif-badge">
                            {(notifications?.length || 0) + (chats?.length || 0)}
                        </span>
                    )} */}
                </button>

                {showNotifications && (
                    <>
                        {isMobile && (
                            <div className="tab-notif-backdrop" onClick={() => setShowNotifications(false)} />
                        )}
                        <div ref={panelRef} className="tab-notif-panel" >
                            <div className="tab-notif-tabs">
                                <button
                                    className={`tab-notif-tab-button ${activeTab === 'notifications' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('notifications')}
                                >
                                    Notifications
                                    {notifications?.length > 0 && (
                                        <span className="tab-notif-tab-badge">{notifications.length}</span>
                                    )}
                                </button>
                                <button
                                    className={`tab-notif-tab-button ${activeTab === 'messages' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('messages')}
                                >
                                    Messages
                                    {chats?.length > 0 && (
                                        <span className="tab-notif-tab-badge">{chats.length}</span>
                                    )}
                                </button>
                            </div>

                            <div className="tab-notif-content">
                                {activeTab === 'notifications' ? (
                                    notifications?.length > 0 ? (
                                        notifications.map(renderNotificationItem)
                                    ) : (
                                        renderEmptyState('notifications')
                                    )
                                ) : chats?.length > 0 ? (
                                    chats.map(renderChatItem)
                                ) : (
                                    renderEmptyState('messages')
                                )}
                            </div>

                        </div>
                    </>
                )}
            </div>

            {isChatOpen && selectedChat && (
                <ChatComponent
                    userId={user.id}
                    senderName={user.name}
                    workerId={selectedChat?.otherParticipant?._id}
                    workerName={selectedChat?.otherParticipant?.name}
                    onClose={() => setIsChatOpen(false)}
                />
            )}
        </>
    );
};

export default NotificationPanel;