import React, { useEffect, useState, useRef } from 'react';
import { Loader2, ArrowUpIcon } from 'lucide-react';
import { io } from "socket.io-client";
import { getAllMessagesOfTheChat } from '../../features/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import "./InAppChat.css";

const ChatComponent = ({ userId, senderName, workerId, onClose, workerName }) => {
    const dispatch = useDispatch();
    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const socketRef = useRef(null);
    const chatContainerRef = useRef(null);

    const { message: fetchedMessages } = useSelector((state) => state.auth);

    // Initialize socket connection
    useEffect(() => {
        const socket = io(process.env.REACT_APP_SERVER_URL);
        socketRef.current = socket;

        socket.on('connect', () => {

            setIsConnected(true);
            socket.emit('joinChat', [userId, workerId]);
        });

        socket.on('disconnect', () => {

            setIsConnected(false);
        });

        socket.on('newMessage', (newMessage) => {

            setMessages(prev => [...prev, newMessage]);

            // Scroll to bottom on new message
            setTimeout(() => {
                if (chatContainerRef.current) {
                    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                }
            }, 100);
        });

        // Cleanup on unmount
        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('newMessage');
            socket.disconnect();
        };
    }, [userId, workerId]);

    // Fetch initial messages
    useEffect(() => {
        const fetchMessages = async () => {
            try {
                setIsLoading(true);
                await dispatch(getAllMessagesOfTheChat([userId, workerId]));
            } catch (error) {
                console.error('Error fetching messages:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMessages();
    }, [dispatch, userId, workerId]);

    // Update messages when fetched from redux
    useEffect(() => {
        if (fetchedMessages) {
            setMessages(Array.isArray(fetchedMessages) ? fetchedMessages : []);

            // Scroll to bottom after loading messages
            setTimeout(() => {
                if (chatContainerRef.current) {
                    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                }
            }, 100);
        }
    }, [fetchedMessages]);


    useEffect(() => {
        setMessages([]);
    }, [userId, workerId]);

    const sendMessage = () => {
        if (!messageText.trim() || !isConnected || !socketRef.current) return;

        const messageData = {
            senderId: userId,
            senderName: senderName,
            text: messageText.trim(),
            createdAt: new Date().toISOString(),
            participants: [userId, workerId]
        };

        // Clear input first
        setMessageText('');

        // Emit the message
        socketRef.current.emit('sendMessage', messageData);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content-chat" style={{ padding: 0 }}>
                <div className="chat-header-in-app">
                    <h2 style={{ color: "white" }}>{workerName ? `Conversation with ${workerName}` : 'Chat'}</h2>
                    {!isConnected && (
                        <div className="connection-status">
                            <Loader2 className="animate-spin h-4 w-4 mr-2" />
                            Connecting...
                        </div>
                    )}
                    <button className="close-button" onClick={onClose} aria-label="Close chat">
                        &times;
                    </button>
                </div>

                <div className="chat-container" ref={chatContainerRef}>
                    {isLoading ? (
                        <div className="loading-container">
                            <Loader2 className="animate-spin" size={48} />
                            <p>Loading messages...</p>
                        </div>
                    ) : (
                        messages.map((msg) => (
                            <div
                                key={msg._id}
                                className={`message ${msg.senderId === userId ? 'outgoing' : 'incoming'}`}
                            >
                                <div className="message-content">{msg.text}</div>
                                <div className="message-timestamp">
                                    {new Date(msg.createdAt).toLocaleTimeString()}
                                </div>
                            </div>
                        ))
                    )}
                </div>


                <div className="chat-input-container">
                    <textarea
                        value={messageText}
                        onChange={(e) => setMessageText(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Type your message here..."
                        disabled={!isConnected}
                        className="chat-input-textarea"
                    />
                    <button
                        onClick={sendMessage}
                        disabled={!isConnected || !messageText.trim()}
                        className="chat-input-button"
                    >
                        <ArrowUpIcon size={18} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatComponent;