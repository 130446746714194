import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import "./HeaderAddressModal.css";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { setLocation, getLocationSuggestions, getReverseGeocode, clearSuggestions } from "../../features/locationSlice";
import { Loader2, LocateFixed, X } from 'lucide-react';
import { updateAddress } from "../../features/AuthSlice";

Modal.setAppElement('#root');

const HeaderAddressModal = ({ open, onClose, address, setAddress }) => {
    const dispatch = useDispatch();
    const [permission, setPermission] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [currentLocationLoading, setCurrentLocationLoading] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchTimeout, setSearchTimeout] = useState(null);
    const { user } = useSelector((store) => store.auth);

    // const storeLocation = useSelector((store) => store.location.location);
    const textualAddress = useSelector((store) => store.location.textual_address);
    // Add this right after your useSelector for suggestions
    const suggestions = useSelector((store) => store.location.suggestions);
    const suggestionRef = useRef(null);

    // Handle text address display
    useEffect(() => {
        if (textualAddress) {
            setAddress(textualAddress.formatted);
        } else {
            setAddress("Not set yet");
        }
        setIsLoading(false);
    }, [textualAddress, setAddress]);

    // Handle clicks outside suggestions
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (suggestionRef.current && !suggestionRef.current.contains(event.target)) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            dispatch(clearSuggestions());
        };
    }, [dispatch]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        const newTimeout = setTimeout(() => {
            if (value.trim()) {
                dispatch(getLocationSuggestions(value.trim()));
                setShowSuggestions(true);
            } else {
                dispatch(clearSuggestions());
                setShowSuggestions(false);
            }
        }, 300);

        setSearchTimeout(newTimeout);
    };

    const handleSuggestionClick = async (suggestion) => {
        const location = {
            latitude: parseFloat(suggestion.lat),
            longitude: parseFloat(suggestion.lon)
        };

        try {
            toast.loading('Updating your location...', { id: 'location-update' });

            const reverseGeocodeResult = await dispatch(getReverseGeocode({
                lat: location.latitude,
                lon: location.longitude
            })).unwrap();

            if (reverseGeocodeResult.success) {
                dispatch(setLocation({
                    location,
                    address: reverseGeocodeResult.address
                }));

                // Get the city with fallbacks
                const city = reverseGeocodeResult.address.components._normalized_city ||
                    reverseGeocodeResult.address.components.district ||
                    reverseGeocodeResult.address.components.town ||
                    'Your selected location';

                if (user) {
                    dispatch(updateAddress({
                        lat: location.latitude,
                        lng: location.longitude,
                        city: city,
                        textAddress: reverseGeocodeResult.address.formatted || suggestion.display_name
                    }));
                }


                setInputValue(reverseGeocodeResult.address.formatted || suggestion.display_name);
                dispatch(clearSuggestions());
                setShowSuggestions(false);

                toast.success(`Location updated to ${city}`, { id: 'location-update' });
                onClose();
            } else {
                toast.error('We couldn\'t get the details for this location. Please try another address or your current location.',
                    { id: 'location-update', duration: 4000 });
            }
        } catch (error) {
            toast.error('Connection issue while updating location. Please check your internet and try again.',
                { id: 'location-update', duration: 4000 });
        }
    };

    const handleLocationClick = async () => {
        if (navigator.geolocation) {
            try {
                setCurrentLocationLoading(true);
                toast.loading('Detecting your location...', { id: 'location-detect' });

                const perm = await navigator.permissions.query({ name: 'geolocation' });
                setPermission(perm.state);

                if (perm.state === 'denied') {
                    toast.error("Location access denied. Please enable location in your browser settings and refresh the page.",
                        { id: 'location-detect', duration: 5000 });
                    setCurrentLocationLoading(false);
                    return;
                }

                // Wrap the geolocation call in a promise to properly handle it
                const getPosition = () => {
                    return new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(
                            position => resolve(position),
                            error => reject(error),
                            {
                                enableHighAccuracy: true,
                                timeout: 15000,
                                maximumAge: 0
                            }
                        );
                    });
                };

                try {
                    const position = await getPosition();
                    const location = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    };

                    toast.loading('Getting your address details...', { id: 'location-detect' });

                    const reverseGeocodeResult = await dispatch(getReverseGeocode({
                        lat: location.latitude,
                        lon: location.longitude
                    })).unwrap();

                    if (reverseGeocodeResult.success) {
                        dispatch(setLocation({
                            location,
                            address: reverseGeocodeResult.address
                        }));

                        // Get the city with fallbacks
                        const city = reverseGeocodeResult.address.components._normalized_city ||
                            reverseGeocodeResult.address.components.district ||
                            reverseGeocodeResult.address.components.town ||
                            'Your current location';

                        if (user) {
                            dispatch(updateAddress({
                                lat: location.latitude,
                                lng: location.longitude,
                                city: city,
                                textAddress: reverseGeocodeResult.address.formatted || 'Your current location'
                            }));
                        }


                        toast.success(`Location set to ${city}`, { id: 'location-detect' });
                        onClose();
                    } else {
                        toast.error(reverseGeocodeResult.message || 'We found your location, but couldn\'t get the address details. Please try again or enter your address manually.',
                            { id: 'location-detect', duration: 4000 });
                    }
                } catch (positionError) {
                    let errorMessage;
                    switch (positionError.code) {
                        case 1:
                            errorMessage = "Location access denied. Please enable location permissions in your browser settings and try again.";
                            break;
                        case 2:
                            errorMessage = "Your current location couldn't be determined. You may be in an area with poor GPS signal.";
                            break;
                        case 3:
                            errorMessage = "Location request timed out. Please try again or enter your address manually.";
                            break;
                        default:
                            errorMessage = "Unable to access your location. Please try again later or enter your address manually.";
                    }

                    toast.error(errorMessage, { id: 'location-detect', duration: 4000 });
                }
            } catch (error) {
                toast.error("Something went wrong with location services. Please try entering your address manually.",
                    { id: 'location-detect', duration: 4000 });
            } finally {
                setCurrentLocationLoading(false);
            }
        } else {
            toast.error("Location services aren't supported by your browser. Please try using a different browser or enter your address manually.",
                { id: 'location-detect', duration: 4000 });
            setCurrentLocationLoading(false);
        }
    };

    if (!open) return null;

    return (
        <Modal
            isOpen={open}
            onRequestClose={onClose}
            contentLabel="Header Address"
            className="modal-content reveal"
            overlayClassName="modal-overlay"
        >
            <button
                onClick={onClose}
                className="absolute top-1.5 right-2.5 p-2 rounded-full hover:bg-gray-200 transition-colors"
            >
                <X size={20} />
            </button>

            <div>
                <h5 className="location_set_heading">
                    {isLoading ? 'Loading your location...' : `Current Location: ${address ? ` ${address}` : "Not set yet"}`}
                </h5>
                <div className="location_input_form" ref={suggestionRef}>
                    <input
                        type="text"
                        placeholder="Search for a city, address, or landmark..."
                        value={inputValue}
                        onChange={handleInputChange}
                        onFocus={() => setShowSuggestions(true)}
                    />
                    {showSuggestions && suggestions?.length > 0 && (
                        <div className="suggestions-dropdown">
                            {suggestions.map((suggestion, index) => (
                                <div
                                    key={index}
                                    className="suggestion-item"
                                    onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {suggestion.display_name}
                                </div>
                            ))}
                        </div>
                    )}
                    {showSuggestions && inputValue.trim() && suggestions?.length === 0 && (
                        <div className="suggestions-dropdown">
                            <div className="suggestion-item no-results">
                                No locations found. Try a different search term.
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="or-container">
                <hr className="line" />
                <span className="or">OR</span>
                <hr className="line" />
            </div>

            <div className="parent_current_location">
                <LocateFixed size={20} color="#12333a" />

                <button
                    className="button_location"
                    onClick={handleLocationClick}
                    disabled={currentLocationLoading}
                >
                    {currentLocationLoading ? (
                        <>Finding your location <Loader2 className='loader_button' /></>
                    ) : (
                        "Use Your Current Location"
                    )}
                </button>
            </div>

            <div className="permission_denied">
                <h5>{permission === 'denied' ? 'Location permission denied. Please enable location in your browser settings and refresh this page.' : ''}</h5>
            </div>
        </Modal>
    );
};

export default HeaderAddressModal;