import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
import { X } from 'lucide-react';

// get user from local storage
const initialState = {
  user: null,
  totalSkills: 0,
  totalWorkers: 0,
  totalUsers: 0,
  totalCities: 0,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: [],
  chat: [],
};

/////////////////////////////sign up user///////////////////////////////
export const signUpUser = createAsyncThunk(
  "auth/signUpUser",
  async (data, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/signUpUser`;
      const response = await axios.post(API_URL, data, { withCredentials: true });

      if (response.data.success === true) {
        thunkAPI.dispatch(getUser());
      }

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);




/////////////////////////////verify user///////////////////////////////
export const verifyUser = createAsyncThunk(
  "auth/verifyUser",
  async ({ verificationCode, token }, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/verifyUser`;
      const response = await axios.post(API_URL, { verificationCode, token }, { withCredentials: true });

      if (response.data.success === true) {
        thunkAPI.dispatch(getUser());
      }

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);





/////////////////////////////sign in user///////////////////////////////
export const signInUser = createAsyncThunk(
  "auth/signInUser",
  async (data, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/signInUser`;
      const response = await axios.post(API_URL, data, { withCredentials: true });

      if (response.data.success === true) {
        thunkAPI.dispatch(getUser());
      }

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);





//////////////////////Resend Verification code///////////////////////////
export const resendVerificationCodeViaText = createAsyncThunk(
  "auth/resendVerificationCodeViaText",
  async ({ data }, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/resendVerificationCodeViaText`;
      const response = await axios.post(API_URL, data);
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response?.data &&
          error.response?.data?.message) ||
        error.response?.data?.status ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);





/////////////////////////////Forgot Password///////////////////////////////
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (data, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/forgotPassword`;
      const response = await axios.post(API_URL, data);

      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);





/////////////////////////////Reset Password///////////////////////////////
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ password, token, SigninProcess }, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/resetPassword`;
      const response = await axios.post(API_URL, {
        password,
        token,
        SigninProcess,
      });
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);






///////////////////////////////Logout User///////////////////////////////
export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/logout`;
    const response = await axios.post(API_URL, {}, { withCredentials: true });

    return response.data;
  } catch (error) {
    const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});






/////////////////////////////Get User///////////////////////////////
export const getUser = createAsyncThunk(
  "auth/getUser",
  async (_, thunkAPI) => {
    try {


      const API_URL = `${process.env.REACT_APP_SERVER_URL}/auth/getUser`;
      const response = await axios.get(API_URL, { withCredentials: true });
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);








/////////////////////////////Contact///////////////////////////////
export const contact = createAsyncThunk(
  "auth/contact",
  async (data, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/user/contact`;
      const response = await axios.post(API_URL, data, { withCredentials: true });
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/////////////////////////////Get All Messages///////////////////////////////
export const getAllMessagesOfTheChat = createAsyncThunk(
  'auth/getAllMessagesOfTheChat',
  async (participants, thunkAPI) => {
    try {
      if (!Array.isArray(participants) || participants.length !== 2) {
        throw new Error('Invalid participants format');
      }
      const config = {
        withCredentials: true,  // Automatically sends cookies along with the request
        params: {
          participants: participants
        }
      };
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/chat/getAllMessagesOfTheChat`,
        config
      );


      return response.data;

    } catch (error) {
      const message =
        (error.response?.data?.message) ||
        error.message ||
        'Failed to fetch messages';

      return thunkAPI.rejectWithValue(message);
    }
  });


/////////////////////////////getAllChatsOfTheUser///////////////////////////////
export const getAllChatsOfTheUser = createAsyncThunk(
  'auth/getAllChatsOfTheUser',
  async (userId, thunkAPI) => {
    try {

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/chat/getAllChatsOfTheUser/${userId}`,
        { withCredentials: true }
      );

      return response.data;

    } catch (error) {
      const message =

        (error.response?.data?.message) ||
        error.message ||
        'Failed to fetch messages';

      return thunkAPI.rejectWithValue(message);
    }
  });

/////////////////////////////get Totals (memas total number of users, workers, cities, skill///////////////////////////////
export const getTotals = createAsyncThunk(
  "auth/getTotals",
  async (_, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/user/getTotals`;
      const response = await axios.get(API_URL);
      return response.data;
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

/////////////////////////////Update address///////////////////////////////
export const updateAddress = createAsyncThunk(
  "auth/updateAddress",
  async (data, thunkAPI) => {
    try {
      const API_URL = `${process.env.REACT_APP_SERVER_URL}/user/updateAddress`;
      const response = await axios.post(API_URL, data, { withCredentials: true });
      return response.data;
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message) || error.response.data.status || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);



export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    resetAll: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      ///////////////////////////Sign Up User////////////////////////////
      .addCase(signUpUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        toast.error(action.payload);
      })
      ///////////////////////////Verify User////////////////////////////
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        toast.success(action.payload.message);
      })
      .addCase(verifyUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      ///////////////////////Sign In User/////////////////////////
      .addCase(signInUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          state.user = action.payload.user;
        }
        state.isLoading = false;
        state.isSuccess = true;
        toast.success((t) => (
          <div className="flex items-center justify-between w-full">
            <div className="pr-3">{action.payload.message}</div>
            <button
              className="text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100 transition-colors flex items-center justify-center"
              onClick={() => toast.dismiss(t.id)}
              aria-label="Dismiss"
            >
              <X size={16} />
            </button>
          </div>
        ), {
          duration: 2000,

        });

      })
      .addCase(signInUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        toast.error(action.payload);
      })
      /////////////Resend Verification code//////////////
      .addCase(resendVerificationCodeViaText.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resendVerificationCodeViaText.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        toast(action.payload.message);
      })
      .addCase(resendVerificationCodeViaText.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message
        toast.error(action.payload.message)
      })
      ///////////////////////Forgot Password/////////////////////////
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast(action.payload.message);
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast(action.payload);
      })
      ///////////////////////Reset Password/////////////////////////
      .addCase(resetPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.message = action.payload.message;
        toast(action.payload.message);
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        toast.error(action.payload);
      })
      ///////////////////////Logout/////////////////////////
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.isError = null; // Reset error on pending
        state.message = null; // Reset success message
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = null; // Clear user data on successful logout
        state.message = action.payload.message;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload || "An error occurred during logout";
      })
      ///////////////////////get User/////////////////////////
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          state.user = action.payload.user;
        }
        state.isLoading = false;
        state.isSuccess = true;
        // toast.success(action.payload.message)
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
        // toast.error(action.payload)
        // toast.error(action.payload)
      })
      ///////////////////////Contact/////////////////////////
      .addCase(contact.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(contact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        // toast.success(action.payload.message);
      })
      .addCase(contact.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      })
      ///////////////////////Get All Messages/////////////////////////
      .addCase(getAllMessagesOfTheChat.pending, (state) => {
        state.isLoading = true;
      }
      )
      .addCase(getAllMessagesOfTheChat.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.chat.messages;
      }
      )
      .addCase(getAllMessagesOfTheChat.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      }
      )
      ///////////////////////Get All Chats/////////////////////////
      .addCase(getAllChatsOfTheUser.pending, (state) => {
        state.isLoading = true;
      }
      )
      .addCase(getAllChatsOfTheUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.chat = action.payload.chats;
        // toast.success(action.payload.message);
      }
      )
      .addCase(getAllChatsOfTheUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.chat = action.payload;
        // toast.error(action.payload);
      }
      )

      /////////////getTotals//////////////
      .addCase(getTotals.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTotals.fulfilled, (state, action) => {
        if (action.payload.success === true) {
          state.totalSkills = action.payload.totalSkills;
          state.totalWorkers = action.payload.totalWorkers;
          state.totalUsers = action.payload.totalUsers;
          state.totalCities = action.payload.totalCities;
        }
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
      })
      .addCase(getTotals.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      //Update address
      .addCase(updateAddress.pending, (state) => {
        state.isLoading = true;
      }
      )
      .addCase(updateAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        // toast.success(action.payload.message);
      }
      )
      .addCase(updateAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // toast.error(action.payload);
      }
      )
  },
});

export const { reset, resetAll } = authSlice.actions;
export default authSlice.reducer;
