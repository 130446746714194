import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    location: JSON.parse(localStorage.getItem('location')) || "",
    textual_address: JSON.parse(localStorage.getItem('textual_address')) || "",
    suggestions: [],
    reverseGeocode: null,
    error: null
};



export const getReverseGeocode = createAsyncThunk(
    "location/reverseGeocode",
    async ({ lat, lon }, thunkAPI) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/location/locationReverse?lat=${lat}&lon=${lon}`
            );
            return response.data;
        } catch (error) {
            const message =
                error.response?.data?.message || error.message || "An error occurred";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getLocationSuggestions = createAsyncThunk(
    "location/autocomplete",
    async (query, thunkAPI) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/location/locationAutocomplete?query=${encodeURIComponent(query)}`
            );
            return response.data;
        } catch (error) {
            const message =
                error.response?.data?.message || error.message || "An error occurred";
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setLocation: (state, action) => {
            const { location, address } = action.payload;
            const locationString = JSON.stringify(location);
            const addressString = JSON.stringify(address);
            state.location = location;
            state.textual_address = address;
            localStorage.setItem('location', locationString);
            localStorage.setItem('textual_address', addressString);
        },
        updateLocation: (state, action) => {
            state.location = action.payload;
        },
        clearSuggestions: (state) => {
            state.suggestions = [];
        },
        reset: (state) => {

            return {
                ...initialState,

                location: state.location,
                textual_address: state.textual_address
            };
        }
    },
    extraReducers: (builder) => {
        builder
            // Reverse Geocoding
            .addCase(getReverseGeocode.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getReverseGeocode.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reverseGeocode = action.payload.data;
            })
            .addCase(getReverseGeocode.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Location Autocomplete
            .addCase(getLocationSuggestions.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getLocationSuggestions.fulfilled, (state, action) => {
                state.isLoading = false;
                state.suggestions = action.payload.data;
            })
            .addCase(getLocationSuggestions.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    }
});

export const { setLocation, updateLocation, clearSuggestions, reset } = locationSlice.actions;
export const locationReducer = locationSlice.reducer;