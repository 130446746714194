import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
    session_id: "",
}

export const saveDonorInfoAndStartCheckout = createAsyncThunk(
    'donation/saveDonorInfoAndStartCheckout',
    async (donorInfo, thunkAPI) => {
        try {
            const API_URL = `${process.env.REACT_APP_SERVER_URL}/donation/saveDonorInfoAndStartCheckout`;
            const response = await axios.post(API_URL, donorInfo);
            return response.data;

        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const getPaymentStatus = createAsyncThunk(
    'donation/getPaymentStatus',
    async (paymentId, thunkAPI) => {
        try {
            const API_URL = `${process.env.REACT_APP_SERVER_URL}/donation/getPaymentStatus/${paymentId}`;
            const response = await axios.get(API_URL);
            return response.data;

        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);



const donationSlice = createSlice({
    name: "donation",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
        resetAll: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
            state.user = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveDonorInfoAndStartCheckout.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(saveDonorInfoAndStartCheckout.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.message;
            })
            .addCase(saveDonorInfoAndStartCheckout.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
                toast.error(action.payload);
            })
            .addCase(getPaymentStatus.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPaymentStatus.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.message;
                state.session_id = action.payload.session_id;

            })
            .addCase(getPaymentStatus.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
                toast.error(action.payload);
            });
    },
});

export const { reset, resetAll } = donationSlice.actions;
export default donationSlice.reducer;
