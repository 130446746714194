import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';



const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  allWorkersPublicAnalytics: '', 
  allUsersPublicAnalytics: '', 
};



/////////////////get Workers Public Statistics///////////////////
export const getWorkersPublicStatistics = createAsyncThunk(
  'analytics/getWorkersPublicStatistics',
  async (_, thunkAPI) => {
    try {  
      
          const API_URL = `${process.env.REACT_APP_SERVER_URL}/analytics/getWorkersPublicStatistics`;

      const response = await axios.get(API_URL);
      return response.data; 
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.response.data.status ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);



///////////////////get Users Public Statistics///////////////////
export const getUsersPublicStatistics = createAsyncThunk(
    'analytics/getUsersPublicStatistics',
    async (_, thunkAPI) => {
      try {  
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/analytics/getUsersPublicStatistics`;
  
        const response = await axios.get(API_URL);
        return response.data
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.response.data.status ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
);





export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
      reset: (state) => {
          state.isLoading = false;
          state.isSuccess = false;
          state.isError = false;
          state.message = "";
      },
  },
  extraReducers: (builder) => {
      builder
      ///////////////////getWorkersPublicStatistics///////////////////
      .addCase(getWorkersPublicStatistics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getWorkersPublicStatistics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.allWorkersPublicAnalytics = action.payload.allData
        })
      .addCase(getWorkersPublicStatistics.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      ///////////////////getUsersPublicStatistics///////////////////
      .addCase(getUsersPublicStatistics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsersPublicStatistics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.allUsersPublicAnalytics = action.payload.allData
      })      
      .addCase(getUsersPublicStatistics.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });

  },
});

export const { reset } = analyticsSlice.actions;
export default analyticsSlice.reducer;
