import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    partners: [],
    favoriteWorkers: [],
    totalFavoriteWorkers: 0,
};

// Register Partner
export const registerPartner = createAsyncThunk(
    "partner/registerPartner",
    async (partnerData, thunkAPI) => {
        try {
            const API_URL = `${process.env.REACT_APP_SERVER_URL}/partner/registerPartner`;
            const response = await axios.post(API_URL, partnerData, { withCredentials: true });
            return response.data;
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                (error.response && error.response.data && error.response.data.status) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue({ message });
        }
    }
);

//////////////////////Register Multiple Workers///////////////////////////
export const registerMultipleWorkers = createAsyncThunk(
    "Worker/registerMultipleWorkers",
    async ({formData, partnerId}, thunkAPI) => {
        try {

            const API_URL = `${process.env.REACT_APP_SERVER_URL}/partner/uploadPartnerWorkerFiles/${partnerId}`;
            const response = await axios.post(API_URL, formData, { withCredentials: true });
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.response.data.status ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const partnerSlice = createSlice({
    name: "partner",
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = "";
        },
        resetAll: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerPartner.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(registerPartner.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.message
                toast.success(action.payload.message);
            })
            .addCase(registerPartner.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload.message;
                toast.error(action.payload.message);
            })

            /////////////Register Multiple Workers//////////////
            .addCase(registerMultipleWorkers.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(registerMultipleWorkers.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.message;
                // toast(action.payload.message);
            })
            .addCase(registerMultipleWorkers.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = action.payload.success;
                state.message = action.payload;
                toast.error(action.payload)
                // toast.error(action.payload);
            })
    }
});

export const { reset, resetAll } = partnerSlice.actions;
export default partnerSlice.reducer;