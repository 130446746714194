import React, { useEffect } from 'react';
import { Card, Button, Row, Col, Typography, Space } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './DonateSomaliaCancel.css';

const { Title, Paragraph } = Typography;

const DonateSomaliaCancel = () => {
    const navigate = useNavigate();

    const handleTryAgain = () => {
        navigate('/donate'); // Adjust this route according to your application's routing
    };

    // useEffect(() => {
    //     const root = document?.querySelector('#root');
    //     if (root) {
    //         root?.querySelector('.Bannerheader')?.style.setProperty('display', 'none');
    //         root?.querySelector('.Footer')?.style.setProperty('display', 'none');
    //         root?.querySelector('.Mob_Navbar')?.style.setProperty('display', 'none');
    //     }
    // }, []);
    return (
        <div className="cancel-page">
            <Row justify="center">
                <Col xs={24} sm={20} md={16} lg={12}>
                    <div className="cancel-container">
                        {/* Cancel Message */}
                        <div className="cancel-message">
                            <CloseCircleFilled className="cancel-icon" />
                            <Title level={2}>Contribution Cancelled</Title>
                            <Paragraph>
                                Your contribution process has been cancelled. No charges have been made to your account.
                            </Paragraph>
                        </div>

                        {/* Actions Card */}
                        <Card className="actions-card">
                            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                                <Paragraph>
                                    If you experienced any technical difficulties or have concerns,
                                    please feel free to try again or contact our support team for assistance.
                                </Paragraph>

                                <Row justify="center" gutter={[16, 16]}>
                                    <Col>
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={handleTryAgain}
                                        >
                                            Try Again
                                        </Button>
                                    </Col>

                                </Row>
                            </Space>
                        </Card>

                        {/* Help Message */}
                        <Card className="help-card">
                            <Paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
                                Need help? Our support team is available to assist you with any questions
                                or concerns about the donation process.
                            </Paragraph>
                        </Card>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default DonateSomaliaCancel;