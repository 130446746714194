import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import './BecomeAPartner.css'
import { X, HelpCircle, Loader2 } from 'lucide-react'
import { Tooltip } from 'antd'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { registerPartner } from '../../features/PartnerSlice'
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { getUser } from '../../features/AuthSlice'

const BecomeAPartner = ({ isOpen, close }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((store) => store.auth);
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm()
    const [phoneValue, setPhoneValue] = useState(user?.phone || '');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    useEffect(() => {
        if (user?.partnerId) {
            close();
        }
    }, [user?.partnerId, close]);

    useEffect(() => {
        if (user) {
            if (user?.name) setValue("name", user?.name);
            if (user?.email) setValue("email", user?.email);
            if (user?.phone) {
                setValue("phone", user?.phone, {
                    shouldValidate: true
                });
                setPhoneValue(user?.phone);
            }
        }
    }, [user, setValue]);



    const onSubmit = (data) => {
        setIsLoading(true);
        const formData = new FormData()
        formData.append('partnerName', data.name)
        formData.append('partnerEmail', data.email)
        formData.append('partnerPhone', data.phone)
        formData.append('partnerWebsite', data.website)
        if (data.logo && data.logo[0]) {
            formData.append('partnerLogo', data.logo[0])
        }




        dispatch(registerPartner(formData))
            .unwrap()
            .then((result) => {
                reset();
                return dispatch(getUser())
                    .unwrap()
                    .catch(error => {
                        console.error('Failed to get updated user data:', error);
                        close();
                    });
            })
            .then(() => {
                close();
            })
            .catch((error) => {
                console.error('Failed to register partner:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        register("phone", {
            required: "Phone number is required",
            validate: {
                validNumber: (value) =>
                    !value || isValidPhoneNumber(value) || "Invalid phone number"
            }
        });

    }, []);

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => { close() }}
            contentLabel="Become a Partner Modal"
            className="become-a-partner-modal"
            overlayClassName="become-a-partner-overlay"
            closeTimeoutMS={200}
            ariaHideApp={false}
        >
            <div className="p-4 sm:p-6 md:p-8 max-w-2xl mx-auto">
                <h4 className="text-lg sm:text-xl font-semibold text-gray-800 mb-4 sm:mb-6 md:mb-8 text-center sm:text-left">
                    Fill in the required details in order to proceed
                </h4>

                <form className='space-y-6 sm:space-y-8' onSubmit={handleSubmit(onSubmit)}>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6'>
                        <div className='flex flex-col gap-1 sm:gap-2'>
                            <label htmlFor="name" className="flex items-center gap-1.5 mb-1">
                                Partner Name <span className="text-red-500">*</span>
                                <Tooltip title="Enter your business or organization name as registered">
                                    <HelpCircle size={14} className="text-gray-400 cursor-help" />
                                </Tooltip>
                            </label>

                            <input
                                {...register("name", {
                                    required: "Name is required" // Adding validation since it's now editable
                                })}
                                type="text"
                                id="name"
                                defaultValue={user?.name || ''}
                                placeholder='Enter your name'
                                className="py-2 px-3 w-full"
                            />
                            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
                        </div>
                        <div className='flex flex-col gap-1 sm:gap-2'>
                            <label htmlFor="email" className="flex items-center gap-1.5 mb-1">
                                Partner Email <span className="text-red-500">*</span>
                                <Tooltip title="We'll use this email for all communications">
                                    <HelpCircle size={14} className="text-gray-400 cursor-help" />
                                </Tooltip>
                            </label>

                            <input
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid email address"
                                    }
                                })}
                                type="email"
                                id="email"
                                defaultValue={user?.email || ''}
                                placeholder='Enter your email'
                                className={`py-2 px-3 w-full ${errors.email ? 'border-red-500' : ''}`}
                            />
                            {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
                        </div>
                    </div>

                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6'>
                        <div className='flex flex-col gap-1 sm:gap-2'>
                            <label htmlFor="phone" className="flex items-center gap-1.5 mb-1">
                                Partner Phone Number <span className="text-red-500">*</span>
                                <Tooltip title="Include country code for international numbers">
                                    <HelpCircle size={14} className="text-gray-400 cursor-help" />
                                </Tooltip>
                            </label>

                            <PhoneInputWithCountrySelect
                                value={phoneValue}
                                onChange={(value) => {
                                    setPhoneValue(value);
                                    setValue("phone", value, {
                                        shouldValidate: true
                                    });
                                }}
                                defaultCountry='PK'
                                international
                                placeholder='Enter your phone number'
                                className={`${errors.phone ? 'border-red-500' : ''}`}
                            />
                            {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone.message}</p>}
                        </div>
                        <div className='flex flex-col gap-1 sm:gap-2'>
                            <label htmlFor="website" className="flex items-center gap-1.5 mb-1">
                                Partner Website
                                <Tooltip title="Must include https:// or http://">
                                    <HelpCircle size={14} className="text-gray-400 cursor-help" />
                                </Tooltip>
                            </label>
                            <input
                                {...register("website", {
                                    pattern: {
                                        value: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
                                        message: "Invalid website URL, include https://"
                                    }
                                })}
                                type="url"
                                id="website"
                                placeholder='Enter your website'
                                className={`py-2 px-3 w-full ${errors.website ? 'border-red-500' : ''}`}
                            />
                            {errors.website && <p className="text-red-500 text-sm mt-1">{errors.website.message}</p>}
                        </div>
                    </div>

                    <div className='flex flex-col gap-1 sm:gap-2'>
                        <label htmlFor="logo" className="flex items-center gap-1.5 mb-1">
                            Partner Logo
                            <Tooltip title="Upload a high-resolution logo (PNG or JPG, max 2MB)">
                                <HelpCircle size={14} className="text-gray-400 cursor-help" />
                            </Tooltip>
                        </label>
                        <input
                            {...register("logo", {
                                validate: {
                                    fileType: value => {
                                        if (!value[0]) return true;
                                        return ['image/jpeg', 'image/png'].includes(value[0].type) || 'Only PNG or JPG files are allowed';
                                    },
                                    fileSize: value => {
                                        if (!value[0]) return true;
                                        return value[0].size <= 2 * 1024 * 1024 || 'File size must be less than 2MB';
                                    }
                                }
                            })}
                            type="file"
                            id="logo"
                            accept="image/*"
                            className={`py-1.5 w-full ${errors.logo ? 'border-red-500' : ''}`}
                        />
                        {errors.logo && <p className="text-red-500 text-sm mt-1">{errors.logo.message}</p>}
                    </div>

                    <div className="mt-6 sm:mt-8">
                        <p className="text-gray-600 mb-3 sm:mb-4">
                            By submitting this form, you agree to our partnership terms and conditions.
                        </p>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className='w-full py-2.5 sm:py-3 bg-[#12333a] text-white font-medium rounded hover:bg-[#0c252a] transition-colors flex items-center justify-center'>
                            {isLoading ? (
                                <>
                                    Submitting
                                    <Loader2 className='animate-spin ml-2' />
                                </>
                            ) : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>

            <div className='absolute top-2 right-2 transition-colors duration-200'>
                <button className='text-gray-500 p-2 hover:bg-gray-100 rounded-full' onClick={() => { close() }}>
                    <X size={24} />
                </button>
            </div>
        </ReactModal>
    )
}

export default BecomeAPartner